import RightMenuDropdown from "components/Dropdowns/RightMenuDropdown";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { showAlert } from "redux/reducer/alertReducer";
import { setPremium } from "redux/reducer/isPremiumReducer";
import LogoDynaskills from "../../assets/img/dynaskillswelcome.png";
import coverLetterImg from "../../assets/icons/coverletter.png";
import "../../assets/styles/counter.css";
import freeIcon from "../../assets/icons/free.png"

const Welcome = ({ cover_letter = false }) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation('site');
    const cvList = useSelector(state => state.cvList, shallowEqual);
    const isPremium = useSelector(state => state.isPremium);
    const changeLanguageHandler = (e) =>
    {
        i18n.changeLanguage(e.target.value)
    }
    const alert  = useSelector(state => state.alert);

    return (
        <div className="container relative  pt-10 pb-20">
            {cover_letter && <div className=" mx-auto flex justify-center"><Link to="/cover-letter" className="uppercase text-center  py-2 px-2  text-white flex flex-col gap-2 justify-center items-center text-2xl s" style={{ background: "#F24822",wordSpacing: "100vh", maxWidth:"180px", boxShadow: "3px 3px 16px 5px #f2482280" }}>
                <img className="w-8" src={coverLetterImg} alt="Cover Letter"></img>
                {t('create_cover_letter')}
            </Link></div>}
            {/* {isPremium ? (
                <div className="absolute right-0 top-0 flex gap-2  items-center py-2 mb-10  font-semibold text-xl " style={{ color: '#ebc351' }}>
                    <img src={freeIcon} alt="free version" />
                    <span>{ t('free_version') }</span>
                </div>
            ) : (
                <button className="absolute right-0 top-0 flex gap-2 shadow-2xl items-center py-2 px-4 rounded-lg text-white font-semibold text-lg bg-lightBlue-500" onClick={(e)=>dispatch(setPremium(false))} >
                <i className="fa fa-crown" style={{ color: '#ebc351' }}></i>
                <span>Try dynaskills® Premium</span>
            </button>
            )} */}
            {/* {!isPremium && <div className="mx-auto flex flex-col items-center gap-5 relative" style={{ width: '500px' }} >
                    <div className="counter text-lightBlue-500">
                        <div className="counter-icon"><i className="fa fa-file"></i></div>
                        <span className="counter-value">{3 - cvList.length}</span>
                        <h3>Trials left</h3>
                    </div>
                    {alert && <div className="bg-lightBlue-500 border-l-4 border-lightBlue-800 text-white py-4 px-6 relative" role="alert">
                        <button className="absolute top-2 right-2 cursor-pointer text-lightBlue-800" onClick={(e)=>dispatch(showAlert(false))}>
                            <i className="fa fa-times"></i></button>
                            <p>You have already used all your 3 cv trials, to upload more <br/><a href='#' className="underline text-lightBlue-800" style={{fontWeight: 'bold'}}>go to premium</a></p>
                    </div>}
                </div>
                
            } */}
            {/* <div className="flex justify-between items-center mt-10">
                <span className="text-lg text-white font-semibold">{t('welcome_to')}</span>
            </div> */}
            <div className="flex justify-between items-end mt-10">
                
                <div></div>
                <select className="ml-3  placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline font-semibold" onChange={e=>changeLanguageHandler(e)} value={i18n.language.substring(0, 2)}>
                    <option value="de">{t('german')}</option>
                    <option value="en">{t('english')}</option>
                    <option value="uk">{t('ukranian')}</option>
                    <option value="fr">{t('french')}</option>
                    <option value="it">{t('italian')}</option>
                    <option value="ar">{t('arabic')}</option>
                    <option value="pt">{t('portuguese')}</option>
                    <option value="ur">{t('urdu')}</option>
                    <option value="zh">{t('chinese')}</option>
              </select>
            </div>
            <div>

            </div>
        </div>  
    );
}

export default Welcome;

