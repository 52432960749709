import React from "react";

import UserDropdown from "components/Dropdowns/UserDropdown.js";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWrench } from '@fortawesome/free-solid-svg-icons'
import questionIcon from "../../assets/icons/question.png"
import { Link } from "react-router-dom";
import LogoDynaskills from "../../assets/img/dynaskillsuprisingwhite.png";

export default function Navbar({ title }) {
  const {t,i18n}= useTranslation('site');
  const email = localStorage.getItem('email');

  const lang = i18n.language.substring(0, 2);
  return (
    <>
      {/* Navbar */}
      <nav className="fixed top-0 left-0 w-full z-10 bg-lightBlue-800 md:flex-row md:flex-nowrap md:justify-start flex items-center h-16 shadow-2xl" style={{zIndex:"9999"}}>
        <div className="container mx-auto items-center flex justify-between md:flex-nowrap flex-wrap ">
          <div className="flex gap-3 items-center flex justify-between md:flex-nowrap flex-wrap">
            <a href="/matching-jobs" className="flex items-center border-r-2 border-white pr-2" >
              <img src={LogoDynaskills} className="w-40 h-auto" alt="dynaskills" />
              <span style={{color: "#04340C"}} className="px-2 text-xs bg-white rounded">Free</span>
            </a>
            <a
              className=" text-white text-lg md:text-sm px-2"
              href={"https://dynaskills-dynajobs.com/faq?lang=" + lang}
              target="_blank"
              rel="noreferrer"
            >
              <span>{t('help_center')}</span>
            </a>
            <Link
              className=" text-white text-lg md:text-sm"
              to="contact"
            >
              <span>{t('support')}</span>
            </Link>
            
          </div>
          
          <div className="flex gap-3 items-center">
            <a
              href={`https://dynaskills-dynajobs.com/dynaskillsPremium?lang=${lang}&email=${email}`}
              style={{ background: "#FF4F79" }}
              className="py-1 px-2 rounded text-white flex gap-2 items-center"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-crown"></i>{t('upgrade_to_premium')}
            </a>
            <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
              <UserDropdown />
            </ul>
          </div>
          
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
